<template>
    <span
        class="text-black text-sm mr-2 px-2.5 py-1 border"
        :class="`bg-${color || 'white'} border-${border || 'black'} text-${textColor || 'black'}`"
    >
        {{ `${text}${remove ? ' ' : ''}` }}<Cross
            v-if="remove"
            class="inline cursor-pointer h-4 hover:text-red-500"
            @click="$emit('remove')"
        />
    </span>
</template>

<script>
import Cross from "@/Components/Svg/Cross.vue";

export default {
    components: {Cross},
    props: {
        text: {
            type: String,
            required: true
        },
        color: String,
        textColor: String,
        border: String,
        remove: {
            type: Boolean,
            default: false
        }
    }
}
</script>
